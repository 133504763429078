import axios from '@/common/js/request'

export function pageStandingBook(data){
    const pageStandingBook = axios({
        url: '/monitorPoint/pageStandingBook',
        method: 'get',
        params:data
    })
    return pageStandingBook
}
export function exportStandingBook(data){
    const exportStandingBook = axios({
        url: '/monitorPoint/exportStandingBook',
        method: 'get',
        params:data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return exportStandingBook
}
export function deleteStandingBook(data){
    const deleteStandingBook = axios({
        url: '/monitorPoint/deleteStandingBook',
        method: 'get',
        params:data
    })
    return deleteStandingBook
}

export function monitorPointDelete(data){
    const monitorPointDelete = axios({
        url: '/monitorPoint/delete',
        method: 'get',
        params:data
    })
    return monitorPointDelete
}
export function monitorPointFindById(data){
    const monitorPointFindById = axios({
        url: '/monitorPoint/findById',
        method: 'get',
        params:data
    })
    return monitorPointFindById
}