<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="监测点/设备名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.name"
              placeholder="请输入"
              clearable
              class="search"
            ></el-input>
            
          </el-form-item>
          <el-form-item label="设备类型" class="searchItem">
            <el-select
                v-model="pageParams.eqTypeCode"
                placeholder="请选择"
                size="mini"
                clearable
                @change="search"
            >
              <el-option
                  v-for="(item, index) in eqTypeCodeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属图层" class="searchItem">
              <el-cascader
                    v-model="pageParams.layerId"
                    :options="optionsList"
                    :props="props"
                    placeholder="请选择图层"
                    popper-class="gis-default"
                    style="width:100%"
                     @change="search"
                  >
                  </el-cascader>
           
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="search" size="mini" >查询</el-button>
             <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
             <el-button type="success" @click="exportFile" size="mini" >导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
        <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin-top: 10px ;"
            border
            :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
            height="calc(100vh - 340px)"
            :stripe="true"
          >
          <el-table-column
            type="index"
            :index="indexMethod"
            label="序号"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="pointName"
            width="130"
            label="监测点名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="eqName"
            label="设备名称"
            width="130"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="eqCode"
            label="设备编码"
            width="130"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="eqTypeName"
            label="设备类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="statusName"
            label="设备状态"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="installDate"
            label="安装日期"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="point"
            label="经纬度"
            show-overflow-tooltip
          >
          <template slot-scope="{row}">{{row.point.replace('POINT(', '').replace(')', '')}}</template>
          </el-table-column>
          
          <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createBy"
            label="操作人"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="操作时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="layerName"
            label="所属图层"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="left" width="150">
            <template slot-scope="{row}">
              <el-button type="text"  @click="lookDesc(row)" size="mini">查看</el-button>
              <el-button type="text" style="color: #FF8610" @click="getDelete(row)" size="mini">删除</el-button>
              <el-button type="text" style="color: #0A8E78" @click="dingwei(row)" size="mini">定位</el-button>
            </template> 
          </el-table-column>
        </el-table>
      <div class="total">
        <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      </div>
    </div>
    </div>
     <el-dialog
      title="仪表设备"
      :visible.sync="equipmentVisible"
      :modal="true"
      :close-on-click-modal="false"
      width="700px"
      @close="closeEquipment"
      custom-class="dialog-class"
    >
      <el-form
        ref="formEq"
        :model="formEq"
        :rules="rulesEquipment"
        label-width="100px"
        size="mini"
      >
      <el-row>
        <el-col>
       <el-form-item v-if="formEq.id" label="检测点名称:">
          <el-input
            v-model="formEq.pointName"
            disabled
            placeholder="请输入检测点名称"
          ></el-input>
        </el-form-item>
        </el-col>
        <el-col>
        <el-form-item v-if="formEq.id" label="所属图层:">
          <el-input
            v-model="formEq.layerName"
            disabled
            placeholder="请输入所属图层"
          ></el-input>
        </el-form-item>
        </el-col>
        <el-col>
        <el-form-item v-if="formEq.id" label="设备编号:">
          <el-input
            v-model="formEq.flowCode"
            disabled
            placeholder="请输入设备编号"
          ></el-input>
        </el-form-item>

       </el-col>
        <el-col :span="12">
        <el-form-item label="设备类型:" prop="eqType">
          <el-select
            v-model="formEq.eqType"
            disabled
            popper-class="gis-default"
            placeholder="请选择设备类型"
            
          >
            <el-option
              v-for="item in eqTypeList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="设备状态:" prop="generalStatus">
          <el-select
            v-model="formEq.generalStatus"
            :disabled="isEdit? false: true"
            popper-class="gis-default"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in generalStatusList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        
        <el-form-item label="规格:">
          <el-input
            v-model="formEq.spec"
            :disabled="isEdit? false: true"
            maxlength="40"
            placeholder="请输入规格"
          ></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="型号:">
          <el-input
          :disabled="isEdit? false: true"
            v-model="formEq.model"
            maxlength="40"
            placeholder="请输入型号"
          ></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="安装日期:" prop="installDate">
          <el-date-picker
          :disabled="isEdit? false: true"
            v-model="formEq.installDate"
            value-format="yyyy-MM-dd"
            type="date"
            :clearable="false"
            :editable="false"
            popper-class="datePickerClass"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        </el-col>
        <el-col :span="12">
         <el-form-item label="是否远传:" >
          <el-select
          :disabled="isEdit? false: true"
            v-model="formEq.transmit"
            popper-class="gis-default"
            placeholder="请选择是否远传"
          >
            <el-option
              v-for="item in transmitList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="是否在线:" >
          <el-select
          :disabled="isEdit? false: true"
            v-model="formEq.online"
            popper-class="gis-default"
            placeholder="请选择是否在线"
          >
            <el-option
              v-for="item in onlineList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="12">
        <el-form-item label="厂家:">
          <el-input
          :disabled="isEdit? false: true"
            v-model="formEq.factory"
            placeholder="请输入厂家"
            maxlength="40"
          ></el-input>
        </el-form-item>
      
        </el-col>
        <el-col :span="12">

        <el-form-item label="附着压力级别:" >
          <el-select
          :disabled="isEdit? false: true"
            v-model="formEq.pressureLevel"
            popper-class="gis-default"
            placeholder="请选择附着压力级别"
          >
            <el-option
              v-for="item in pressureLevelList"
              :key="item.dictCode"
              :label="item.name"
              :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
       </el-col>
        <el-col :span="24">
        <el-form-item label="备注:">
         <el-input
         :disabled="isEdit? false: true"
              v-model="formEq.remark"
              type="textarea"
              :rows="2"
              maxlength="60"
              placeholder="请输入备注"
            ></el-input>
        </el-form-item>
       </el-col>
        <el-col :span="24">
        
        <el-form-item label="经纬度:">
          <el-input
            v-model="formEq.point"
            disabled
            placeholder="请输入经纬度"
          ></el-input>
        </el-form-item>
        </el-col>
        <el-col :span="24">
        
        <el-form-item label="所在地址:">
          <el-input
            v-model="formEq.address"
            :disabled="isEdit? false: true"
            placeholder="请输入所在地址"
          ></el-input>
        </el-form-item>
        </el-col>
         </el-row>
      </el-form>
     
      <div class="btn">
        <el-button size="mini" @click="closeEquipment(1)">取消</el-button>
        <el-button size="mini" type="primary" v-if="isEdit" @click="submitEquipment">保存</el-button>
        <el-button size="mini" type="primary" v-else @click="eidtStatus(row)">编辑</el-button>
      </div>
    </el-dialog>
    <station-location ref="stationLocation"></station-location>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import { exportMethod } from '../../common/js/exportExcel'

import {pageStandingBook,exportStandingBook,monitorPointDelete, monitorPointFindById, deleteStandingBook} from "../../RequestPort/gisbook/eq.js"
import { serverMap } from '../Gis/utils/dict';
import stationLocation from './stationLocation.vue';
import { flowFindById, flowUpdateById, pressureFindById, pressureUpdateById } from '../Gis/apis/tool';
import coverlayer from "../Gis/apis/coverlayer";
export default {
  components: { stationLocation },
  mixins: [pageMixins],
  data() {
    return {
      userTypeList:[],
      peopleList:[],
      eqTypeCodeList:[
        {id:1,name:'流量计'},{id:2,name:'压力计'}
      ],
      pageParams: {
        name:'',
        eqTypeCode:'',
        current: 1,
        size: 100,
      }, //  分页查询参数
      total:null,
      tableData: [], //  表格数据
      title:'',
      formEq:{},
      equipmentVisible:false,
      isEdit:false,
      eqTypeList: serverMap['1001'],
      flowType1List: [], //分类一先空着吧
     
      generalStatusList: serverMap['1009'],
      pressureLevelList: serverMap['1006'],
      transmitList: serverMap['1010'],
      onlineList: serverMap['1011'],
      pressureOffsetList: serverMap['1010'], //温压补偿没有
   
      protectLevelList: [],
      rulesEquipment: {
        flowName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { max: 100, message: '超出最大限制', trigger: 'blur' }
        ],
        eqType: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
      
        generalStatus: [{ required: true, message: '请选择状态', trigger: 'change' }],
        
      },
      formOld:{},
      optionsList:[],
      props: {
        emitPath: false,
        label: 'name',
        value: 'id'
      },
    };
  },
  mounted() {
    this.getOption()
    this.loadList(this.pageParams)
  },
  methods: {
    async getOption(){
       let p = { sysType: 1}
        let res = await coverlayer.getFindTree(p)
        this.optionsList = res.data
    },
    exportFile(){
      exportStandingBook(this.pageParams).then(res=>{
        exportMethod(res,'仪表设备台账')
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
      };
      this.loadList(this.pageParams);
    },
    loadList(obj){
      pageStandingBook(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    getDelete(row){
        this.$confirm(`是否确认删除【${row.eqName}】设备信息？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        deleteStandingBook({id:row.id,eqTypeCode:row.eqTypeCode}).then(res=>{
          this.$message.success(res.msg);
          this.loadList(this.pageParams);
         })
      });
      
    },
    closeEquipment(falg){
      if(this.isEdit&&falg==1&&this.formEq.id){
        this.isEdit = false
        this.formEq = this.formOld
      }else{
        this.equipmentVisible = false
        this.formEq = {}
      }
      
    },
    lookDesc(row){
        this.equipmentVisible = true
        this.isEdit = false
        if(row.eqTypeCode==1){
          flowFindById({id:row.id}).then(res=>{
            this.formEq = res.data;
            this.formEq.eqType = "11"
            this.formEq.point = row.point.replace('POINT(', '').replace(')', '')
            this.formEq.pointName = row.pointName
          })
        }else{
          pressureFindById({id:row.id}).then(res=>{
            this.formEq = res.data;
            this.formEq.flowCode = res.data.pressureCode
            this.formEq.eqType = "12"
             this.formEq.point = row.point.replace('POINT(', '').replace(')', '')
             this.formEq.pointName = row.pointName
          })
        }
      
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    eidtStatus(){
      this.isEdit = true;
      this.formOld = this.formEq
    },
    submitEquipment(){
       let that = this;
        that.$refs.formEq.validate(valid => {
        if (valid) {
            if (that.formEq.eqType != serverMap['1001'][0].dictCode) {
              //压力计
              let data = {
                pressureName: that.formEq.flowName,
                pressureCode: that.formEq.flowCode,
                pressureType1: that.formEq.flowType1,
                ...that.formEq
              }
              //编辑压力计
              pressureUpdateById(data)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.isEdit = false
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.loadList(this.pageParams)
                })
                // .catch(err => {
                //   that.$message.error(err)
                // })
            }else{
              //编辑流量计
              flowUpdateById(that.formEq)
                .then(res => {
                  if (res.code == 200) {
                    that.$message.success(res.msg)
                    that.isEdit = false
                  } else {
                    that.$message.error(res.msg)
                  }
                  this.loadList(this.pageParams)
                })
                // .catch(err => {
                //   that.$message.error(err)
                // })
            }
        }
        })
    },
    dingwei(row){
      let lonlat = row.point
      let data = {}
      if(row.eqTypeCode==1){
          flowFindById({id:row.id}).then(res=>{
            
            data = res.data;
            data.eqType = "11"
            data.point = row.point.replace('POINT(', '').replace(')', '')
            data.pointName = row.pointName
            data.showType = 'eq'
        row.title="设备信息"
        this.$refs.stationLocation.openDialog(data,lonlat)
          })
        }else{
          pressureFindById({id:row.id}).then(res=>{
            data= res.data;
            data.eqType = "12"
             data.point = row.point.replace('POINT(', '').replace(')', '')
             data.pointName = row.pointName
              data.showType = 'eq'
        row.title="设备信息"
        this.$refs.stationLocation.openDialog(data,lonlat)
          })
        }
      
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { taskCode: id, disable: disable } })
    },

  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{    
  height: 25px;
}
.searchItem {
  margin-bottom: 1%;
  margin-left: 1%;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.btn{text-align: right;}
.search{
  width: 190px;
}
.contain{
  height: 690px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>